import httpClient from '../httpClient/httpClient';

const HfeaBackportsService = {
  uploadXmlFile(locationID, xmlFile, onUploadProgress) {
    // 50MB is maximum request size, leave 0.5MB for request-overhead
    const maxRequestSize = 49.5 * 1024 * 1024;

    if (xmlFile.size > maxRequestSize) {
      return Promise.reject({ fileSizeError: true, message: 'File is larger than maximum file-size of 49.5 MB'});
    }

    const formData = new FormData();
    formData.append("file", xmlFile);
    return httpClient.post('api/upload-hfea-xml-file', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        locationID,
      },
      onUploadProgress,
    });
  },
  getAlreadyUploadedXmlFiles(locationID) {
    return httpClient.get('api/get-already-uploaded-hfea-files', {
      params: {
        locationID,
      },
    });
  },
  deleteXmlFile(locationID, filename) {
    return httpClient.post('api/delete-hfea-file', {
      locationID,
      filename,
    });
  }
};

export default HfeaBackportsService;
