<template>
  <v-container v-if="hasPermission" fluid>
    <mex-sperm-spinner v-if="locationViewLoading" :spinnerText="getLocationLoadingSpinnerText" />
    <template v-else>
      <v-row justify="center">
        <v-col cols="12">
          <mex-heading content="Location View" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <mex-sheet  color="background" rounded sheetPadding="2">
            <v-row>
              <v-col align-self="center" cols="4"></v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="selectedLocation"
                  :filled="editMode"
                  :items="locationNames"
                  :readonly="editMode"
                  color="primaryAccent"
                  hide-details
                  label="Location Name"
                  outlined
                  dense
                  @change="selectionChanged"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                      <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-row v-if="clinicData && !clinicDataLoading" justify="end">
                  <v-slide-x-transition>
                    <v-col v-if="editMode" cols="1">
                      <mex-btn icon="mdi-content-save" iconOnly @click="saveChanges" />
                    </v-col>
                  </v-slide-x-transition>
                  <v-slide-x-transition>
                    <v-col v-if="editMode" cols="1">
                      <mex-btn icon="mdi-undo" iconOnly @click="getLocationViewData" />
                    </v-col>
                  </v-slide-x-transition>
                  <v-col cols="1">
                    <mex-btn v-if="writeLocations" :color="editMode ? 'primary' : ''" icon="mdi-pencil" iconOnly @click="editMode = true" />
                  </v-col>
                  <v-col align-self="center" cols="1">
                    <mex-btn icon="mdi-refresh" iconOnly @click="getLocationViewData" />
                  </v-col>
                  <v-col align-self="center" cols="1">
                    <mex-btn
                      v-if="createNotes"
                      :color="noteDialogMinimized ? 'note' : ''"
                      :iconOnly="!noteDialogMinimized"
                      content="Open Note"
                      icon="mdi-file-plus"
                      @click="showNoteDialog = !showNoteDialog"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </mex-sheet>
        </v-col>
      </v-row>
      <mex-sperm-spinner v-if="locationDataLoading" :spinnerText="getLocationLoadingSpinnerText" />
      <template v-else>
        <v-row>
          <v-col cols="8">
            <v-row>
              <v-col cols="8">
                <expansion-sheet v-if="locationData && !locationViewLoading" color="foreground" rounded>
                  <template v-slot:header>
                    <mex-p content="Location Info" fontSize="h5" fontWeight="bold-italic" />
                  </template>
                  <template v-slot:content>
                    <v-row justify="center">
                      <v-col align-self="center" cols="6">
                        <v-text-field
                          v-model="locationData.name"
                          :filled="!editMode"
                          :readonly="!editMode"
                          color="primaryAccent"
                          dense
                          hide-details
                          label="Location name"
                          outlined
                        />
                      </v-col>
                      <v-col align-self="center" cols="6">
                        <v-text-field
                          v-model="locationData.locationUUID"
                          color="primaryAccent"
                          dense
                          filled
                          hide-details
                          label="Location UUID"
                          outlined
                          readonly
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col align-self="center" cols="6">
                        <v-text-field
                          v-model="locationData.registerLocationID"
                          color="primaryAccent"
                          dense
                          filled
                          hide-details
                          label="Register location ID"
                          outlined
                          readonly
                        />
                      </v-col>
                      <v-col align-self="center" cols="6">
                        <v-menu
                          ref="installationDateMenu"
                          v-model="installationDateMenu"
                          :close-on-content-click="false"
                          :return-value.sync="locationData.installationDate"
                          min-width="auto"
                          offset-y
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="getFormattedInstallationDate"
                              color="primaryAccent"
                              dense
                              filled
                              hide-details
                              label="Installed at"
                              outlined
                              :readonly="!editMode"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-if="editMode"
                            v-model="locationData.installationDate"
                            :max="getCurrentDate"
                            min="2000-01-01"
                            no-title
                            scrollable
                            @change="$refs.installationDateMenu.save(locationData.installationDate)"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </template>
                </expansion-sheet>
              </v-col>
            </v-row>
            <v-row v-if="clinicData && isHfeaClinic">
              <v-col cols="8">
                <expansion-sheet v-if="locationData && !locationViewLoading" color="foreground" rounded :start-expand="false">
                  <template v-slot:header>
                    <mex-p content="Upload XML-File" fontSize="h5" fontWeight="bold-italic" />
                  </template>
                  <template v-slot:content>
                    <template v-if="alreadyUploadedXmlFiles.length">
                      <h3>Already uploaded files:</h3>
                      <br>
                    </template>
                    <mex-sheet v-if="alreadyUploadedXmlFiles.length" color="dark" rounded>
                      <v-chip-group>
                        <v-chip
                          v-for="filename in alreadyUploadedXmlFiles"
                          :key="filename"
                          close
                          @click:close="deleteXmlFile(filename)"
                        >{{filename}}</v-chip>
                      </v-chip-group>
                    </mex-sheet>
                    <template v-if="alreadyUploadedXmlFiles.length">
                      <br>
                      <h3>Upload new file(s):</h3>
                      <br>
                    </template>
                    <XmlFileUpload ref="xmlFileUpload" color="dark" @uploadedFiles="uploadXmlFiles($event)" :already-uploaded-xml-filenames="alreadyUploadedXmlFiles"></XmlFileUpload>
                  </template>
                </expansion-sheet>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex" cols="6" style="flex-direction: column">
                <expansion-sheet v-if="organizationData && !locationViewLoading" color="foreground" rounded>
                  <template v-slot:header>
                    <v-row>
                      <v-col align-self="center" cols="11">
                        <mex-p class="mb-0" content="Organization Info" fontSize="h5" fontWeight="bold-italic" />
                      </v-col>
                      <v-col align-self="center" cols="1">
                        <mex-btn v-if="readOrganization" icon="mdi-arrow-right-bold-hexagon-outline" iconOnly @click="goToOrganizationView" />
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:content class="mb-1 flex-grow-1">
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="organizationData.name"
                          color="primaryAccent"
                          dense
                          filled
                          hide-details
                          label="Organization name"
                          outlined
                          readonly
                        />
                      </v-col>
                    </v-row>
                  </template>
                </expansion-sheet>
              </v-col>
              <v-col class="d-flex" cols="6" style="flex-direction: column">
                <expansion-sheet v-if="systemUserData && !locationViewLoading" color="foreground" rounded>
                  <template v-slot:header>
                    <mex-p content="System Users" fontSize="h5" fontWeight="bold-italic" />
                  </template>
                  <template v-slot:content class="mb-1 flex-grow-1">
                    <v-select
                      v-model="selectedSystemUsers"
                      :items="systemUserNames"
                      dense
                      filled
                      hide-details
                      label="System-Users"
                      multiple
                      outlined
                      readonly
                    >
                      <template v-slot:selection="data">
                        <v-chip class="ma-1" dense>
                          <v-icon class="mr-1" x-small>mdi-account</v-icon>
                          {{ data.item.text }}
                        </v-chip>
                      </template>
                    </v-select>
                  </template>
                </expansion-sheet>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <expansion-sheet v-if="organizationData && !locationViewLoading" color="foreground" rounded>
                  <template v-slot:header>
                    <v-row>
                      <v-col align-self="center" cols="auto">
                        <mex-p class="mb-0" content="Clinic Info" fontSize="h5" fontWeight="bold-italic" />
                      </v-col>
                      <v-col align-self="center">
                        <mex-btn v-if="readClinics" icon="mdi-arrow-right-bold-hexagon-outline" iconOnly @click="goToClinicView" />
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:content>
                    <v-row>
                      <v-col cols="4">
                        <v-row>
                          <v-col align-self="center" cols="12">
                            <v-text-field
                              v-model="clinicData.name"
                              color="primaryAccent"
                              dense
                              filled
                              hide-details
                              label="Clinic name"
                              outlined
                              readonly
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col align-self="center" cols="12">
                            <v-text-field
                              v-model="clinicData.clinicUUID"
                              color="primaryAccent"
                              dense
                              filled
                              hide-details
                              label="Clinic UUID"
                              outlined
                              readonly
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col align-self="center" cols="12">
                            <v-text-field
                              v-model="clinicData.customerNumber"
                              :filled="!editMode"
                              readonly
                              color="primaryAccent"
                              dense
                              hide-details
                              label="Customer Number"
                              outlined
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="4">
                        <v-row>
                          <v-col align-self="center" cols="12">
                            <v-text-field
                              v-model="clinicData.Infrastructures[0].meditexVersion"
                              color="primaryAccent"
                              dense
                              filled
                              hide-details
                              label="Current MedITEX Version"
                              outlined
                              prepend-icon="mdi-tag"
                              readonly
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col align-self="center" cols="12">
                            <v-text-field
                              v-model="$dateFormatter.convertJsonDate(clinicData.Infrastructures[0].meditexUpdateDate).date"
                              dense
                              filled
                              hide-details
                              label="Updated at"
                              outlined
                              prepend-icon="mdi-update"
                              readonly
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col align-self="center" cols="12">
                            <v-switch
                              class="ma-0"
                              :prepend-icon="clinicData.blocklisted ? 'mdi-headset-off' : 'mdi-headset'"
                              v-model="clinicData.blocklisted"
                              :label="`Clinic is ${clinicData.blocklisted ? '' : 'not'} blocklisted`"
                              readonly
                              color="error"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="4">
                        <v-row>
                          <v-col align-self="center" cols="10">
                            <v-text-field
                              v-model="$dateFormatter.convertJsonDate(clinicData.lastContact).full"
                              dense
                              filled
                              hide-details
                              label="Last contact at"
                              outlined
                              prepend-icon="mdi-access-point-check"
                              readonly
                            />
                          </v-col>
                          <v-col align-self="center" cols="2">
                            <mex-status-lamp v-if="$lastContactEvaluation.lastContactWithin24h(clinicData.lastContact)" color="success" />
                            <mex-status-lamp v-else-if="!$lastContactEvaluation.lastContactWithin48h(clinicData.lastContact)" blink color="error" />
                            <mex-status-lamp v-else color="warning" />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col align-self="center" cols="10">
                            <v-text-field
                              v-if="$deactivationStateEvaluation.isForcedDeactivationSet(clinicData.Locations[0].forcedDeactivationDate)"
                              v-model="$dateFormatter.convertJsonDate(clinicData.Locations[0].forcedDeactivationDate).full"
                              dense
                              filled
                              hide-details
                              label="Forced deactivation date"
                              outlined
                              readonly
                            >
                              <template v-slot:prepend>
                                <v-icon
                                  v-if="$deactivationStateEvaluation.isDeactivated(clinicData.Locations[0].forcedDeactivationDate)"
                                  color="error"
                                >
                                  mdi-lock-alert
                                </v-icon>
                                <v-icon
                                  v-else-if="$deactivationStateEvaluation.isForcedDeactivationSet(clinicData.Locations[0].forcedDeactivationDate)"
                                  color="warning"
                                >
                                  mdi-lock-open-alert
                                </v-icon>
                              </template>
                            </v-text-field>
                            <v-text-field
                              v-else
                              v-model="$dateFormatter.convertJsonDate(clinicData.Locations[0].deactivationDate).full"
                              dense
                              filled
                              hide-details
                              label="Current deactivation date"
                              outlined
                              readonly
                            >
                              <template v-slot:prepend>
                                <v-icon color="success"> mdi-lock-open-check</v-icon>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col
                            v-if="!$deactivationStateEvaluation.isForcedDeactivationSet(clinicData.Locations[0].forcedDeactivationDate)"
                            align-self="center"
                            cols="2"
                          >
                            <mex-btn dense icon="mdi-lan-disconnect" iconOnly readonly @click="showFddDialog = !showFddDialog" />
                          </v-col>
                          <v-col v-else align-self="center" cols="2">
                            <mex-btn dense icon="mdi-lan-connect" iconOnly readonly @click="showFddDialog = !showFddDialog" />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </template>
                </expansion-sheet>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <expansion-sheet v-if="locationData && !locationViewLoading" color="foreground" rounded>
                  <template v-slot:header>
                    <mex-p content="Contacts" fontSize="h5" fontWeight="bold-italic" />
                  </template>
                  <template v-slot:content></template>
                </expansion-sheet>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <expansion-sheet v-if="locationData && !locationViewLoading" color="foreground" rounded>
                  <template v-slot:header>
                    <mex-p content="Address" fontSize="h5" fontWeight="bold-italic" />
                  </template>
                  <template v-slot:content>
                    <v-expansion-panels>
                      <v-expansion-panel v-for="(address, idx) in locationData.Addresses" :key="idx">
                        <v-expansion-panel-header> {{ address.AddressType.name.toUpperCase() }} </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <address-view
                            :address="address"
                            :clinicID="clinicData.ClinicID"
                            :editMode="editMode"
                            :organizationID="organizationData.OrganizationID"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel v-if="editMode">
                        <v-expansion-panel-header>
                          <v-row justify="start">
                            <v-col cols="auto">
                              <v-icon>mdi-map-marker-plus</v-icon>
                            </v-col>
                            <v-col cols="auto">
                              <mex-p class="mb-0" content="Add new address" />
                            </v-col>
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content></v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </template>
                </expansion-sheet>
              </v-col>
            </v-row>
            <v-row v-if="locationData && !locationViewLoading" color="foreground" rounded>
              <v-col cols="12">
                <mex-sheet color="foreground" rounded>
                  <v-row>
                    <v-col cols="12">
                      <mex-p content="Statistics" fontSize="h5" fontWeight="bold-italic" />
                      <v-row justify="center">
                        <v-col cols="auto">
                          <mex-btn
                            v-if="readCycleTypesStatistics"
                            content="Cycle Types Statistic"
                            @click="goToCycleTypeStatistics"
                          />
                        </v-col>
                        <v-col cols="auto">
                          <mex-btn
                            v-if="readEndpointsStatistics"
                            content="Endpoints Statistic"
                            @click="goToEndpointsStatistics"
                          />
                        </v-col>
                        <v-col cols="auto">
                          <mex-btn
                            icon="mdi-puzzle"
                            v-if="readFeatureLicense"
                            content="Feature View"
                            @click="goToFeatureView" />
                        </v-col>
                        <v-col cols="auto">
                          <mex-btn
                            icon="mdi-puzzle-check"
                            v-if="featureSettings"
                            content="Feature Settings"
                            @click="goToFeatureSettings" />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </mex-sheet>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <note-sheet v-if="viewNotes && locationData && !locationViewLoading" :notes="notes" @reloadNotes="getNotes" />
          </v-col>
        </v-row>
      </template>
    </template>
    <note-dialog
      v-if="locationData && !locationDataLoading"
      key="clinicViewNoteDialog"
      :preSelection="getNotePreSelection"
      :showNoteDialog="showNoteDialog"
      @accept="getNotes"
      @minimize="setNoteDialogMinimized"
      @reject="setNoteDialogDiscard"
    />
    <v-overlay  v-if="isUploading" >
      <mex-sperm-spinner :spinnerText="isUploadingText" />
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import NoteDialog from '../../components/LicSrvComponents/NoteDialog.vue';
import ClinicsService from '../../services/clinics.service';
import NotesService from '../../services/notes.service';
import LocationsService from '../../services/locations.service';
import HfeaBackportsService from "../../services/hfeaBackports.service";
import NoteSheet from '../../components/LicSrvComponents/NoteSheet.vue';
import SystemUsersService from '../../services/systemUsers.service';
import OrganizationsService from '../../services/organizations.service';
import AddressView from '../../components/LicSrvComponents/AddressView.vue';
import ExpansionSheet from '../../components/LicSrvComponents/ExpansionSheet.vue';
import keyListenerManager from '../../functions/keyListenerManager';
import requiredPermissions from '../../requiredPermissions';
import XmlFileUpload from '../../components/LicSrvComponents/XmlFileUpload';
import RegisterTypesService from "../../services/registerTypes.service";
import MexSheet from "../../components/MedITEX_Vue_Components/MexComponents/MexSheet";
import {assignPropertyIfExists} from "../../functions/assignPropertyIfExists";

export default {
  name: 'LocationView',
  components: {
    MexSheet,
    XmlFileUpload,
    NoteDialog,
    NoteSheet,
    AddressView,
    ExpansionSheet,
  },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    getLocationLoadingSpinnerText() {
      return this.locationViewLoadingText;
    },
    getNotePreSelection() {
      return {
        organization: this.organizationData.OrganizationID,
        clinic: this.clinicData.ClinicID,
        location: this.selectedLocation,
        systemUsers: this.selectedSystemUsers,
      };
    },
    viewNotes() {
      return this.getUserPermissions.includes('view_Customer Administration_Note');
    },
    createNotes() {
      return this.getUserPermissions.includes('create_Customer Administration_Note');
    },
    writeLocations() {
      return this.getUserPermissions.includes('write_Customer Administration_Location Administration');
    },
    readOrganization() {
      return this.getUserPermissions.includes('read_Customer Administration_Organization Administration');
    },
    readClinics() {
      return this.getUserPermissions.includes('read_Customer Administration_Clinic Administration');
    },
    writeClinics() {
      return this.getUserPermissions.includes('write_Customer Administration_Clinic Administration');
    },
    readCycleTypesStatistics() {
      return this.getUserPermissions.includes("_Statistics_Cycle Types");
    },
    readEndpointsStatistics() {
      return this.getUserPermissions.includes("_Statistics_Endpoints");
    },
    featureSettings() {
      return this.getUserPermissions.includes('_License Administration_License Settings');
    },
    readFeatureLicense() {
      return this.getUserPermissions.includes('read_License Administration_Feature License');
    },
    getCurrentDate() {
      return new Date(new Date(new Date().setDate(new Date().getDate() - 1))).toISOString();
    },
    getFormattedInstallationDate() {
      return this.$dateFormatter.convertJsonDate((new Date(this.locationData.installationDate)).toISOString()).date
    }
  },
  data() {
    return {
      // visualization
      locationViewLoadingText: '',
      locationViewLoading: false,
      locationDataLoading: false,
      showNoteDialog: false,
      noteDialogMinimized: false,
      // data
      locationNames: [],
      selectedLocation: '',
      locationData: null,
      systemUserData: null,
      selectedSystemUsers: [],
      systemUserNames: [],
      clinicName: '',
      clinicData: null,
      organizationData: null,
      notes: [],
      xmlFiles: [],
      alreadyUploadedXmlFiles: [],

      // edit
      editMode: false,
      editModeAndSaveShortCutListener: null,
      boundEditModeAndSaveShortCutListener: null,
      hasPermission: false,
      installationDate: null,
      installationDateMenu: false,
      isHfeaClinic: false,

      // sperm spinner
      isUploading: false,
      isUploadingText: '',
    };
  },
  methods: {
    initialSetup() {
      this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.locationAdministration, this.$store)
        .then((hasPermission) => {
          if (hasPermission) {
            this.hasPermission = true;
            this.getSelectData();
            this.selectedLocation = assignPropertyIfExists([this.$route.params, this.$route.query], 'id', this.$store, 'locationView', 'setLocationView');
            if (this.getUserPermissions.includes('write_Customer Administration_Location Administration')) {
              this.manageKeyListener();
            }
          } else {
            this.$router.push({ name: "NotFound" });
          }
        })
        .catch(() => {
          this.$router.push({ name: "NotFound" });
        });
    },
    deleteXmlFile(filename) {

      HfeaBackportsService.deleteXmlFile(this.selectedLocation, filename)
        .then(async () => {
          this.$toast.success('Deleted XML-file ' + filename);
          HfeaBackportsService.getAlreadyUploadedXmlFiles(this.selectedLocation)
            .then((response) => {
              this.alreadyUploadedXmlFiles = response.data.hfeaBackportFilenames;
              this.$refs.xmlFileUpload.alreadyUploadedXmlFilenames = this.alreadyUploadedXmlFiles;
              this.$refs.xmlFileUpload.updateDuplicateFiles();
            })
            .catch(() => {
              this.$toast.error('Couldn\'t reload already uploaded XML-files');
            });
        })
        .catch(() => {
          this.$toast.error('Couldn\'t delete XML-file ' + filename);
        });

    },
    async uploadXmlFiles(fileArray) {

      this.xmlFiles = fileArray;

      this.isUploading = true;
      for (let i = 0; i < this.xmlFiles.length; i++) {
        this.isUploadingText = 'Uploading file ' + this.xmlFiles[i].name;
        try {
          await HfeaBackportsService.uploadXmlFile(this.selectedLocation, this.xmlFiles[i], event => this.progress = Math.round((100 * event.loaded) / event.total));
          this.$toast.success('Uploaded file ' + this.xmlFiles[i].name + ' successfully');
        } catch(error) {
          let errorMessage = 'Couldn\'t upload XML-file ' + this.xmlFiles[i].name;
          if (error.fileSizeError) {
           errorMessage += ': ' + error.message;
          } else if (error.response.status === 400) {
            errorMessage += ': ' + error.response.data.message;
          }
          this.$toast.error(errorMessage);
        }
      }
      this.isUploading = false;

      this.alreadyUploadedXmlFiles = (await HfeaBackportsService.getAlreadyUploadedXmlFiles(this.selectedLocation)).data.hfeaBackportFilenames;

    },
    selectionChanged(value) {
      this.selectedLocation = value;
    },
    getLocationViewData() {
      this.editMode = false;
      this.locationDataLoading = true;
      this.locationViewLoadingText = 'loading location data';
      LocationsService.getLocationData(this.selectedLocation)
        .then((locationResponse) => {
          this.locationData = locationResponse.data;
          this.locationViewLoadingText = 'loading clinic data';
          ClinicsService.getClinicData(this.locationData.ClinicID)
            .then((clinicResponse) => {
              this.clinicData = clinicResponse.data;
              RegisterTypesService.checkIfHfea(this.clinicData.RegisterTypeID)
                .then((response) => {
                  this.isHfeaClinic = response.data.isHfea;
                  this.locationViewLoadingText = 'loading organization data';
                  OrganizationsService.getOrganizationData(this.clinicData.OrganizationID)
                    .then((organizationResponse) => {
                      this.organizationData = organizationResponse.data;
                      this.locationViewLoadingText = 'loading system user data';
                      SystemUsersService.getSystemUsersByOrganization(this.organizationData.OrganizationID)
                        .then(async (systemUsersResponse) => {
                          this.systemUserData = systemUsersResponse.data;
                          this.selectedSystemUsers = [];
                          this.systemUserData.forEach((systemUser) => {
                            this.selectedSystemUsers.push(systemUser.SystemUserID);
                          });
                          if (this.isHfeaClinic) {
                            HfeaBackportsService.getAlreadyUploadedXmlFiles(this.selectedLocation)
                              .then((response) => {
                                this.alreadyUploadedXmlFiles = response.data.hfeaBackportFilenames;
                                this.getNotes();
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          } else {
                            this.getNotes();
                          }
                        })
                        .catch((err) => {
                          this.locationDataLoading = false;
                          this.$toast.error(err.response.data);
                        });
                    })
                    .catch((err) => {
                      this.locationDataLoading = false;
                      this.$toast.error(err.response.data);
                    })
                })
                .catch((err) => {
                  this.locationDataLoading = false;
                  this.$toast.error(err.response.data);
                });
            })
            .catch(() => {
              this.locationDataLoading = false;
              this.$toast.error('Couldn\'t load clinic-data');
            });
        })
        .catch((err) => {
          this.$toast.error(err);
          this.locationDataLoading = false;
        });
    },
    getNotes() {
      this.showNoteDialog = false;
      this.locationViewLoadingText = 'loading notes';
      NotesService.getNotes(this.organizationData.OrganizationID, this.clinicData.ClinicID, this.locationData.LocationID)
        .then((notesResponse) => {
          this.notes = notesResponse.data;
          this.locationDataLoading = false;
        })
        .catch((err) => {
          this.locationDataLoading = false;
          this.$toast.error(err.response.data);
        });
    },
    getSelectData() {
      this.locationViewLoading = true;
      LocationsService.getLocationNames()
        .then((locationResponse) => {
          locationResponse.data.forEach((location) => {
            this.locationNames.push({
              value: location.LocationID,
              text: location.name,
              description: `${location.Clinic.Organization.name} | ${location.Clinic.name}`,
            });
            SystemUsersService.getSystemUserNames()
              .then((systemUserResponse) => {
                systemUserResponse.data.forEach((systemUser) => {
                  this.systemUserNames.push({
                    value: systemUser.SystemUserID,
                    text: systemUser.username,
                  });
                  this.locationViewLoading = false;
                });
              })
              .catch((err) => {
                this.$toast.error(err);
              });
          });
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
          this.locationViewLoading = false;
        });
    },
    saveChanges() {
      LocationsService.updateLocationData(this.locationData.LocationID, this.locationData.name, new Date(this.locationData.installationDate))
        .then((response) => {
          this.$toast.success('Update successful');
          this.$router.push({
            name: 'ParamsReload',
            params: { name: 'LocationView', reloadParams: { id: response.data.LocationID } },
          });
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
        });
    },
    setNoteDialogDiscard() {
      this.showNoteDialog = false;
      this.noteDialogMinimized = false;
    },
    setNoteDialogMinimized() {
      this.showNoteDialog = false;
      this.noteDialogMinimized = true;
    },
    goToOrganizationView() {
      this.$router.push({ name: 'OrganizationView', params: { id: this.organizationData.OrganizationID } });
    },
    goToClinicView() {
      this.$router.push({ name: 'ClinicView', params: { id: this.clinicData.ClinicID } });
    },
    manageKeyListener() {
      keyListenerManager.editModeAndSaveShortCut(
        this,
        document,
        'editModeAndSaveShortCutListener',
        'boundEditModeAndSaveShortCutListener',
        'editMode',
        'getLocationViewData',
        'saveChanges',
      );
    },
    goToCycleTypeStatistics() {
      this.$router.push({
        name: "ClinicCycleTypesStatistics",
        params: {
          organizationID: this.organizationData.OrganizationID,
          clinicID: this.clinicData.ClinicID,
          startDate: "2010-01-01"
        }
      });
    },
    goToEndpointsStatistics() {
      this.$router.push({
        name: "ClinicEndpointsStatistics",
        params: {
          organizationID: this.organizationData.OrganizationID,
          clinicID: this.clinicData.ClinicID
        },
        startDate: "2010-01-01"
      });
    },
    goToFeatureSettings() {
      this.$router.push({
        name: 'FeatureLicenseSettings',
        params: { organizationID: this.organizationData.OrganizationID, clinicID: this.clinicData.ClinicID, locationID: this.locationData.LocationID },
      });
    },
    goToFeatureView() {
      this.$router.push({
        name: "FeatureLicenseView",
        params: { organizationID: this.organizationData.OrganizationID, clinicID: this.clinicData.ClinicID, locationID: this.locationData.LocationID }
      });
    }
  },
  watch: {
    selectedLocation: {
      handler() {
        this.getLocationViewData();
        this.$store.commit('selectedProperties/setLocationView', this.selectedLocation);
      },
    },
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.boundEditModeAndSaveShortCutListener);
  },
  created() {
    this.initialSetup();
  },
};
</script>

<style scoped>
.stickyHeader {
  position: sticky;
  top: 51px;
  z-index: 10;
}
</style>
