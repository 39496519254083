<template>
  <v-card
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    :color="$props.color"
  >
    <v-card-text>
      <v-row class="d-flex flex-column" dense align="center" justify="center">
        <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
          mdi-cloud-upload
        </v-icon>
        <mex-p content="Drop the XML-file here ..."/>
      </v-row>
      <v-virtual-scroll
        v-if="uploadedFiles.length > 0"
        :items="uploadedFiles"
        height="150"
        item-height="50"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.name">
            <v-list-item-content>
              <v-list-item-title class="">
                {{ item.name }}
                <span class="ml-3 text--secondary">
                    {{ item.size }} bytes
                  </span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click.stop="removeUploadFile(item.name)" icon>
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card-text>
    <v-card-actions>
      <v-file-input
        label="... or select from explorer"
        accept=".xml"
        multiple
        v-model="explorerUploadedFiles"
        @change="explorerXmlFilesChanged"
      >
        <template v-slot:selection="{ text, index }">
          <v-chip small text-color="white" color="primary" close @click:close="removeXmlFile(index)">
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>
      <v-spacer></v-spacer>
      <v-btn @click="removeUploadedFiles" icon>
        <v-icon id="close-button">mdi-close</v-icon>
      </v-btn>
      <v-btn @click="submit" icon>
        <v-icon id="upload-button">mdi-upload</v-icon>
      </v-btn>
    </v-card-actions>
    <v-alert dense type="error" v-if="duplicateUploadFiles.length">Files with types equal to <b>{{ duplicateUploadFileString }}</b> were already uploaded for this location and will be overwritten with upload</v-alert>
  </v-card>
</template>

<script>

export default {
  name: "FileUpload",
  components: {
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'black',
    },
    alreadyUploadedXmlFilenames: {
      default: [],
    }
  },
  data() {
    return {
      dragover: false,
      uploadedFiles: [],
      explorerUploadedFiles: [],
      duplicateUploadFiles: [],
      duplicateUploadFileString: '',
    };
  },
  methods: {
    updateDuplicateFiles() {
      this.duplicateUploadFiles = this.uploadedFiles.filter((uploadedFile) => this.alreadyUploadedXmlFilenames.filter((alreadyUploadedXmlFile) => (uploadedFile.name.split('-')[1] && alreadyUploadedXmlFile.split('-')[1]) ? uploadedFile.name.split('-')[1] === alreadyUploadedXmlFile.split('-')[1] : uploadedFile.name === alreadyUploadedXmlFile).length).map(file => file.name);
      this.duplicateUploadFileString = this.duplicateUploadFiles.join(', ');
    },
    explorerXmlFilesChanged() {
      this.uploadedFiles = this.uploadedFiles.concat(this.explorerUploadedFiles);
      this.explorerUploadedFiles = [];
      this.updateDuplicateFiles();
    },
    onDrop(upload) {
      if (!this.multiple && upload.dataTransfer.files.length > 1) {
        this.$toast.error('Only one file can be uploaded at a time');
      } else {
        for (let i = 0; i < upload.dataTransfer.files.length; i++) {
          if (upload.dataTransfer.files[i].name.split('.').pop() !== 'xml') {
            this.$toast.error('Only XML-files can be uploaded here');
            return;
          }
        }
        for (let i = 0; i < upload.dataTransfer.files.length; i++) {
          this.uploadedFiles.push(upload.dataTransfer.files[i]);
        }
      }
      this.updateDuplicateFiles();
    },
    removeUploadedFiles() {
      this.uploadedFiles = [];
      this.duplicateUploadFiles = [];
    },
    removeUploadFile(fileName) {
      const index = this.uploadedFiles.findIndex((file) => file.name === fileName);
      if (index > -1) {
        this.uploadedFiles.splice(index, 1);
      }
      this.updateDuplicateFiles();
    },
    submit() {
      if (this.uploadedFiles.length === 0) {
        this.$toast.error('No files to upload');
        return;
      }
      this.$emit('uploadedFiles', this.uploadedFiles);
      this.uploadedFiles = [];
      this.duplicateUploadFiles = [];
    },
  }
};
</script>

<style scoped></style>
